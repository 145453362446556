<template>
  <div class="eyes" v-if="content">
        <div
            class="content1"
            :style="{'background-image':content.productStyle.content1.resourceUrl?`url(${content.productStyle.content1.resourceUrl})`:''}"
        >
            <div >
<!--              <div class="type">慧眼系列</div>-->
<!--              <div class="title">{{content.product.productName}}</div>-->
<!--              <div class="text">{{content.product.productDesc}}</div>-->

            </div>
        </div>
        <div class="content2"
             :style="{'background-image':content.productStyle.content2.resourceUrl?`url(${content.productStyle.content2.resourceUrl})`:''}"

        >
            <div v-animate-onscroll.repeat="{down:'downToTop'}">
              <div class="title">{{content.productStyle.content2.title}}</div>
              <div class="text">{{content.productStyle.content2.description}}</div>

            </div>
        </div>
        <div class="content3" style="text-align: center;padding: 128px 0 64px;">
          <img :src="content.productStyle.img1" alt="" width="960px" height="400px">
        </div>
        <div class="icon-list" v-animate-onscroll.repeat="{down:'swing'}">
            <div class="icon-item"
                 v-for="(item,index) in content.productStyle.icons"
                 :class="content.productStyle.icons.length==6?'box-6':''"
                 :key="index"
            >
                <div><img :src="item.icon" alt="" width="64px" height="64px"></div>
                <div class="title">{{item.title}}</div>
            </div>
        </div>
        <div class="info">
            <div class="info-content"
                 v-for="(item,index) in content.productStyle.list1"
                 v-show="activeIndex==index"
                 :key="index"
            >
              <div class="title">{{item.title}}</div>
              <div class="text">{{item.description}}</div>
                <div style="margin-top: 50px">
                  <img :src="item.resourceUrl" width="1200px" height="600px">
                </div>
            </div>
            <div class="tools-box">
                <div class="tools-item"
                     v-for="(item,index) in content.productStyle.list1"
                     :class="activeIndex==index?'active':''"
                     :key="index"
                     @click="activeIndex=index"
                >
                  {{item.name}}
                </div>
            </div>
    </div>
        <div class="info">
            <div class="title">{{content.productStyle.content3.title}}</div>
            <div class="text">{{content.productStyle.content3.description}}</div>
            <div class="content4">
                <div class="icon-list">
                        <div class="icon-item"
                             v-for="(item,index) in content.productStyle.content3.icons"
                             :key="index"
                        >
                          <div><img width="75px" height="75px" :src="item.icon" alt=""></div>
                          <div>{{item.title}}</div>
                        </div>
                </div>
                <div >
                  <img width="800px" height="450px" :src="content.productStyle.content3.resourceUrl" alt="">
                </div>
            </div>
      </div>


        <div class="info">
      <div class="info-content"
           v-for="(item,index) in content.productStyle.list2"
           v-show="listActive==index"
           :key="index"
      >
        <div class="title">{{item.title}}</div>
        <div class="text">{{item.description}}</div>
        <div style="margin-top: 50px">
          <img :src="item.resourceUrl" alt="" width="1200px" height="600px">
        </div>
      </div>
      <div class="tools-box">
        <div class="tools-item"
             v-for="(item,index) in content.productStyle.list2"
             :class="listActive==index?'active':''"
             :key="index"
             @click="listActive=index"
        >
          {{item.name}}
        </div>
      </div>
    </div>


        <div class="info">
            <div class="title">硬件参数</div>
          <div class="text-info">
            <span style="margin-left: 50px;font-size: 24px"> 型号：{{content.product.productName}}</span>
          </div>
            <div class="ql-editor" v-html="content.productStyle.content4.productParameter"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "eyes",
  data(){
    return {
      content:null,
      activeIndex:0,
      listActive:0
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
  },
  methods:{
    getDetail(){
          this.$get('/website/product/style2/'+this.$route.query.id).then((res)=>{
              this.content=res.data;
          }).catch((err)=>{

          })
    },
  }
}
</script>
<style lang="scss">
@keyframes downToTop {
  0%{
    opacity: 0;
    transform: translateY(60px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}
.downToTop{
  animation: downToTop 1.5s;
}
@keyframes swing {
  0% {
    transform: translateY(60px) scale(0.6);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
.swing {
  animation: swing 1s;
}
</style>
<style scoped lang="scss">
.eyes{
  padding-bottom: 50px;
  .content1{
    width: 100%;
    height: 880px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    color: black;
    display: flex;
    align-items: center;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .type{
      font-size: 32px;
      margin-bottom: 10px;
    }
    .text{
      font-size: 22px;
    }
    >div{
      margin-left: 300px;
    }
  }
  .content2{
    width: 100%;
    height: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    .title{
      font-size: 48px;
      margin-bottom: 20px;
    }
    .text{
      font-size: 18px;
    }
  }
  .img1{
    width: 100%;
    height: 320px;
  }
  .icon-list{
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .icon-item{
          width: 25%;
          text-align: center;
          margin-top: 30px;
    }
    .icon-item.box-6{
        width: 33%;
    }
  }
  .info,.parameter{
    width: 1200px;
    margin: 0 auto;
    .title{
      font-size: 34px;
      text-align: center;
      margin-top: 128px;

    }
    .text{
      margin: 0 auto;
      margin-top: 20px;
      width: 800px;
      text-align: center;
    }
    .url-box{
      margin-top: 30px;
    }
    .info-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .info-list-item{
        width: 50%;
        padding: 0 50px;
        background: transparent;
      }
      .info-list-item:hover{
        background: #181818;
        transition: all 1s;
      }
    }
    .info-content{

    }
    .tools-box{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #3F3F3F;
      .tools-item{
        padding: 5px 10px;
        color: #5A5A5A;
        border-top: 1px solid transparent;
        cursor: pointer;
      }
      .tools-item.active{
        color: black;
        border-top: 1px solid black;
      }
    }
    .content4{
        display: flex;
        margin-top: 50px;
        .icon-list{
          width: 400px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .icon-item{
            width: 50%;
            text-align: center;
            margin-top: 50px;
          }
        }

    }
  }
  .ql-editor{
    height: 430px;
    margin-top: 50px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background-color: #797979;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // border-radius: 10px;
      background: transparent;
    }
  }

}
</style>
